import { Row } from "antd";
import logo from "../../assets/svg/logo.svg";
import styles from "./index.module.css";

const PageNav = () => {
  return (
    <Row className={styles.holding_space}>
      <div className={styles.content_div}>
        <img src={logo} alt="Theia" />
        <h1 className={styles.first_heading}>Theia pay (Beta)</h1>
        <h2 className={styles.second_heading}>
          Earn points and preserve cashflow
        </h2>
      </div>
    </Row>
  );
};

export default PageNav;
