import { Col, Collapse, Row, Steps } from "antd";
import { useNavigate } from "react-router-dom";
import "antd/dist/antd.min.css";
import styles from "./index.module.css";
import CollapseHeader from "./CollapseHeader";
import search from "assets/svg/search.svg";
import { ReactComponent as DocumentIcon } from "assets/svg/doc_grey.svg";
import "./antCustom.css";
import format from "date-fns/format";
import Loader from "./Loader";
import { IInvoice, STATUS } from "../../../store/invoice/invoiceSlice";
import { useState } from "react";

const { Panel } = Collapse;

interface InvoiceListProps {
  loading: boolean;
  invoices: IInvoice[];
}

const InvoicesList = ({ invoices, loading }: InvoiceListProps) => {
  const { Step } = Steps;
  const navigate = useNavigate();

  const [selectedKey, setSelectedKey] = useState<string | string[]>();
  const onChangeHandler = (key: string | string[]) => {
    setSelectedKey(key);
  };

  const progressStatus = (status: string) => {
    switch (status) {
      case "reviewing":
        return 0;
      case "sent pay link":
        return 1;
      case "ready to pay":
        return 2;
      case "settled":
        return 3;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWarpper}>
        <button
          className={styles.createPaymentButton}
          onClick={() => {
            navigate("/invoices/create");
          }}
        >
          <span className={styles.plus}>+</span> Create new payment
        </button>
        <Collapse
          className={styles.antCollapse}
          expandIconPosition="end"
          accordion
          onChange={(key) => onChangeHandler(key)}
        >
          {loading ? (
            <Loader loading={loading} />
          ) : (
            invoices.map((invoice) => {
              return (
                <Panel
                  className={[
                    styles.antCollapseItem,
                    selectedKey === invoice.pid && styles.activeHeaderItem,
                  ].join(" ")}
                  header={
                    <CollapseHeader
                      header={invoice.name}
                      created={format(
                        new Date(invoice.created),
                        "dd MMMM yyyy"
                      )}
                    />
                  }
                  key={invoice.pid}
                >
                  <Row style={{ width: "100%" }}>
                    <Col span={6}>
                      <img src={search} alt="" className={styles.image_div} />
                    </Col>
                    <Col span={10} className={styles.names_div}>
                      <h2 className={styles.names}>{invoice.name}</h2>
                      <p className={styles.payment_type}>
                        <span className={styles.bank_account}></span>{" "}
                      </p>
                    </Col>
                    <Col span={6}>
                      {invoice.status === STATUS.REVIEWING ? (
                        <>
                          <h3 className={styles.reviewing_status}>In Review</h3>
                        </>
                      ) : (
                        <>
                          {" "}
                          <h3 className={styles.totals}>
                            HKD $
                            {invoice.fees
                              ? invoice.fees + invoice.amount
                              : invoice.amount}
                          </h3>
                          <h4 className={styles.fees}>
                            <span>fees: {invoice.fees}</span>
                          </h4>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row className={styles.lower_row}>
                    <Steps
                      progressDot
                      current={progressStatus(invoice.status)}
                      direction={
                        window.innerWidth < 650 ? "vertical" : "horizontal"
                      }
                    >
                      <Step description="Review your request and update you via email within 24h, usually much faster" />
                      <Step description="Receive a Theia Pay link" />
                      <Step description="Pay us with your credit card, we arrange payout" />
                      <Step description="Bill settled" />
                    </Steps>
                  </Row>
                </Panel>
              );
            })
          )}
          {invoices.length === 0 && !loading && (
            <div className={styles.noPayment}>
              <DocumentIcon />
              <p>No payment</p>
            </div>
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default InvoicesList;
