import {Auth} from 'aws-amplify';
import {CognitoUser} from '@aws-amplify/auth';
import { env } from 'config';

type IUserAttributes = {
    'custom:company_name': string;
    'custom:usage': string;
    'custom:full_name': string;
    email: string;
    email_verified: boolean | undefined;
    phone_number: string;
    phone_number_verified: boolean;
    sub: string;
};
export type User = CognitoUser & { attributes: IUserAttributes };

export interface ICreateAccount {
    email: string;
    password: string;
    phone_number: string;
    company_name: string;
    full_name: string;
    usage: string
}
export interface IUserSignIn {
    username: string;
    password: string;
}

// eslint-disable-next-line
export default {
    signup: async (account: ICreateAccount) => {
        // @ts-ignore
        window.intercomSettings = {
            api_base: 'https://api-iam.intercom.io',
            app_id: env === 'PROD' ? 'f6he6a5c' : 'bjoblqqc',
            name: account.full_name,
            email: account.email,
            tag: 'theia-pay'
          };
          // @ts-ignore
          window.Intercom('update');

        return await Auth.signUp({
            username: account.email,
            password: account.password,
            attributes: {
              phone_number: account.phone_number,
              email: account.email,
              'custom:company_name': account.company_name,
              'custom:full_name': account.full_name,
              'custom:usage': 'theia-pay'
            },
        });
    },
    signin: async ({ username, password }: IUserSignIn) => {
        const user: User = await Auth.signIn(username, password);

        // @ts-ignore
        window.intercomSettings = {
            api_base: 'https://api-iam.intercom.io',
            app_id: env === 'PROD' ? 'f6he6a5c' : 'bjoblqqc',
            name: user?.attributes?.['custom:full_name'],
            email: user?.attributes?.email,
            tag: 'theia-pay'
        };
        // @ts-ignore
        window.Intercom('update');

        return user;
    },
    sendOTPConfirmation: async (username: string) => {
        return await Auth.forgotPassword(username);
    },
    resetPassword: async (username: string, code: string, password: string) => {
        return await Auth.forgotPasswordSubmit(username, code, password);
    },
    confirmSignUp: async (username: string, code: string) => {
        return await Auth.confirmSignUp(username, code);
    },
    signOut: async () => {
        return await Auth.signOut();
    },
}
