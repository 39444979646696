import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {createInvoice, fetchInvoices} from "../../utils/invoiceAPI";

export interface IError {
  message: string;
  success: string;
}

export enum STATUS {
  SETTLED = 'settled',
  REVIEWING = 'reviewing',
  SENT_PAY_LINK = 'sent pay link',
  READY_TO_PAY = 'ready to pay'
}

export interface IInvoice {
  amount: string;
  created: string;
  fees: string;
  invoice_url: string;
  name: string;
  pid: string;
  status: STATUS;
  uid: string;
  updated: string;
}

export interface IInvoiceRequest {
  invoiceUrl: string;
  invoiceName: string;
}

export interface InvoiceState {
  invoicesList: IInvoice[];
  status: 'idle' | 'pending' | 'success' | 'failed';
  loading: boolean;
  error: IError | null;
  uploading: boolean;
}

const initialState: InvoiceState = {
  invoicesList: [],
  status: "idle",
  loading: false,
  uploading: false,
  error: null,
};

export const getInvoicesAsync = createAsyncThunk(
  "invoice/fetchLists",
  async () => {
    const response = await fetchInvoices();
    return response.data;
  }
);

export const createInvoiceAsync = createAsyncThunk(
  "invoice/create",
  async (data: IInvoiceRequest) => {
    const response = await createInvoice(data);
    return response.data;
  }
);

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    uploadDocument: (state) => {
      state.loading = true;
      state.error = {} as IError;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoicesAsync.pending, (state) => {
        state.status = "pending";
        state.loading = true;
      })
      .addCase(getInvoicesAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.loading = false
        state.invoicesList = action.payload;
      })
      .addCase(getInvoicesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error =  action.error as IError;
        state.loading = false
      })
      .addCase(createInvoiceAsync.pending, (state) => {
          state.uploading = true;
        })
      .addCase(createInvoiceAsync.fulfilled, (state, action) => {
        state.uploading = false;
      })
      .addCase(createInvoiceAsync.rejected, (state, action) => {
        state.uploading = false;
        state.error = action.error as IError;
      });
  },
});

export const selectInvoices = (state: RootState) => state.invoice;
export const { uploadDocument } = invoiceSlice.actions;

export default invoiceSlice.reducer;
