import { SignIn, SignUp, Recover } from "rightPanel/auth";
import Layout from "components/layout";
import AuthPage from "pages/AuthPage";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import InvoicesPage from "pages/invoices/InvoicesPage";
import CreateInvoice from "rightPanel/invoices/createInvoice/createInvoice";
import InvoicesDetails from "rightPanel/invoices/details";
import { useAuthentication } from "../hooks/useAuth";

const ProtectedRoute = () => {
  const { user } = useAuthentication();
  return user ? <Outlet /> : <Navigate to={"auth/signin"} />;
};

function TheiaPayRouter() {
  return (
    <Routes>
      <Route
        path="auth"
        element={
          <Layout>
            <AuthPage />
          </Layout>
        }
      >
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="recover" element={<Recover />} />
        <Route path="*" element={<Navigate to="/auth/signin" replace />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path="invoices"
          element={
            <Layout>
              <InvoicesPage />
            </Layout>
          }
        >
          <Route path=":invoiceId" element={<InvoicesDetails />} />
          <Route path="create" element={<CreateInvoice />} />
        </Route>
      </Route>
      <Route path="/" element={<Navigate to="/auth/signin" replace />} />
      <Route path="*" element={<p>404 not found</p>} />
    </Routes>
  );
}

export default TheiaPayRouter;
