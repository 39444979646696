export const apiVersion = process.env.REACT_APP_API_VERSION || 'v1';
export const appVersion = process.env.REACT_APP_VERSION || '0.0.1';
export const env = (process.env.REACT_APP_ENV as keyof typeof appConfig) || 'GITSTART';

interface IConfig {
  endpoint: string;
  awsConfig: {
    region?: string;
    userPoolId?: string;
    appClientId?: string;
    userPoolWebClientId?: string;
    identityPoolId?: string;
    Auth?: {
      region: string;
      userPoolId: string;
      appClientId: string;
      userPoolWebClientId: string;
      identityPoolId: string;
    };
    Storage?: {
      AWSS3: {
        bucket: string;
        region: string;
      };
    };
  };
}
const appConfig = {
  DEV: {
    endpoint: `https://ebs5m2i9pl.execute-api.ap-east-1.amazonaws.com/latest/`,
    awsConfig: {
      Auth: {
        region: "ap-northeast-1",
        userPoolId: "ap-northeast-1_2Kw3PKCrn",
        appClientId: "7oladm64ie40eoo6ihej1cn9dv",
        userPoolWebClientId: "7oladm64ie40eoo6ihej1cn9dv",
        identityPoolId: "ap-northeast-1:22abaffd-a8fb-4476-b59f-5096b386415f",
      },
      Storage: {
        AWSS3: {
          bucket: "theia-pay-upload-findev", //REQUIRED -  Amazon S3 bucket name
          region: "ap-east-1", //OPTIONAL -  Amazon service region
        },
      },
    },
  },
  PROD:{
    endpoint: `https://swokvp0fpl.execute-api.ap-east-1.amazonaws.com/latest/`,
    awsConfig: {
      Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_fqKHPWYWv',
        appClientId: '5ea3o356lgm50hkuuqgftbmsqj',
        userPoolWebClientId: '5ea3o356lgm50hkuuqgftbmsqj',
        identityPoolId: 'ap-northeast-1:73cfa2d1-164d-483c-896a-2b5f870b6d09',
      },
      Storage: {
        AWSS3: {
          bucket: 'theiapay-upload', //REQUIRED -  Amazon S3 bucket name
          region: 'ap-east-1', //OPTIONAL -  Amazon service region
        },
      },
    },
  },
  GITSTART: {
    endpoint: `https://07gbhf55oi.execute-api.ap-east-1.amazonaws.com/latest/`,
    awsConfig: {
      Auth: {
        region: "ap-northeast-1",
        userPoolId:
          process.env.REACT_APP_USER_POOL || "ap-northeast-1_4BjUnV5AZ",
        appClientId:
          process.env.REACT_APP_CLIENT_ID || "6mto99vogbdsc3htibfrbjomaq",
        userPoolWebClientId:
          process.env.REACT_APP_CLIENT_ID || "6mto99vogbdsc3htibfrbjomaq",
        identityPoolId: "ap-northeast-1:7564decc-c93f-4850-a689-1e7de13e2dd6",
      },
      Storage: {
        AWSS3: {
          bucket: "theia-pay-upload-gitstart",
          region: "ap-northeast-1",
        },
      },
    },
  },
};

const config: IConfig = appConfig[env];

if (!config) {
  throw new Error(`Invalid environment: ${process.env.REACT_APP_ENV}`);
}

export default config;
