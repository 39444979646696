import { Input } from "antd";
import { useState } from "react";
import Button from "components/Button";
import UploadDragger from "./UploadDragger";
import styles from "./upload-documents.module.css";
import "./antd-customize.css";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  createInvoiceAsync,
  getInvoicesAsync,
  selectInvoices,
} from "store/invoice/invoiceSlice";
import { useNavigate } from "react-router-dom";
import openNotification from "components/Notification";

const UploadDocuments = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { uploading } = useAppSelector(selectInvoices);
  const [documentURL, setDocumentURL] = useState("");
  const [documentName, setDocumentName] = useState("");

  const onSubmitHandle = async () => {
    const response: {
      invoice_url: string;
      name: string;
      status: string;
    } = await dispatch(
      createInvoiceAsync({
        invoiceUrl: documentURL,
        invoiceName: documentName,
      })
    ).unwrap();

    if (response.status) {
      await dispatch(getInvoicesAsync());
      navigate("/invoices");
      openNotification({
        key: "Create_Invoices_Sucessful",
        message: "Invoice Uploaded Successfully",
        description:
          "We will review your request and update you via email within 24hrs.",
        status: "success",
      });
    }
  };

  return (
    <div>
      <div className={styles.heading}>Upload</div>
      <p className={styles.instruction}>
        Upload the invoice you wish to pay, and feel free to include any
        additional documentation you deem appropriate.
      </p>
      <div className={styles.drag}>
        <UploadDragger setPath={setDocumentURL} />
      </div>

      <Input
        className={styles.invoiceName}
        size="large"
        placeholder="Name this invoice, max 20 characters"
        type={"text"}
        maxLength={20}
        onChange={(e) => {
          setDocumentName(e.currentTarget.value);
        }}
      />
      <div>
        <Button
          loading={uploading}
          onClick={onSubmitHandle}
          className={styles.uploadBtn}
          type="primary"
        >
          Upload document
        </Button>
      </div>
    </div>
  );
};

export default UploadDocuments;
