import request from "./request";
import axios from "axios";
import {IInvoiceRequest} from "../store/invoice/invoiceSlice";
import {apiVersion} from "../config";

export async function fetchInvoices() {
  try {
    const response = await request.get(`${apiVersion}/theiapay/invoices`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.messages)
    }
  }
}

export async function createInvoice(data: IInvoiceRequest) {
  try {
    const response  = await request.post(`${apiVersion}/theiapay/invoices`, {
      invoice_url: data.invoiceUrl,
      name: data.invoiceName
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error?.response?.data?.messages)
    }
  }
}
