import auth, {ICreateAccount, IUserSignIn, User} from 'utils/auth';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {RootState} from "../store";
import { IResetPassword, IResetPasswordPayload} from './types';

export interface IAuthError {
  message: string;
  code: string;
}

export interface UserState {
  status: 'idle' | 'pending' | 'success' | 'failed';
  user: User | null;
  signup:{
    success: boolean;
    emailConfirmationSuccess: boolean;
  },
  loading: boolean;
  error: IAuthError | null;
  resetPassword: IResetPassword;
}

const initialState: UserState = {
  user: null,
  status: 'idle',
  loading: false,
  error: {} as IAuthError,
  signup:{
    success:false,
    emailConfirmationSuccess: false,
  },
  resetPassword: {
    username: '',
    isComplete: false,
    loading: false,
    error: null,
    otpSent: false,
  }
}

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({username, code, password}: IResetPasswordPayload) => {
    return await auth.resetPassword(username, code, password);
  })

export const sendConfirmation = createAsyncThunk(
  'auth/sendConfirmation',
  async (username: string) => {
    return await auth.sendOTPConfirmation(username);
  })

export const login = createAsyncThunk('auth/login', async (user: IUserSignIn) => {
  const userResponse = await auth.signin(user);

  if (userResponse.attributes['custom:usage'] !== "theia-pay") {
    await auth.signOut();
    throw new Error('Please contact us');
  }

  return userResponse;
})

export const signUp = createAsyncThunk('auth/signup', async (user: ICreateAccount) => {
  return await auth.signup(user);
})

export const confirmEmail = createAsyncThunk('auth/confirmEmail', async ({code, username}:{code:string, username:string}) => {
  return await auth.confirmSignUp(username, code);
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
  return await auth.signOut()
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    sendingOTPInProgress: (state:UserState, action: PayloadAction<string>) => {
      state.resetPassword.isComplete = false;
      state.resetPassword.username = action.payload;
      state.resetPassword.otpSent= false;
      state.resetPassword.loading = true;
    },
    passwordResetInProgress: (state:UserState) => {
      state.resetPassword.isComplete = false;
      state.resetPassword.loading = true;
    },
    clearUpState: (state:UserState) => {
      state.signup.emailConfirmationSuccess = false;
      state.error = null;
      state.signup.success = false;
    },
    currentUser: (state, action) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'pending';
        state.loading = true;
      }).addCase(login.fulfilled, (state, action) => {
      state.status = 'success';
      state.loading = false;
      state.user = action.payload;
    }).addCase(login.rejected, (state, action) => {
      state.status = 'failed';
      state.loading = false;
      state.error = action.error as IAuthError;
    });
    builder
      .addCase(signUp.pending, (state) => {
        state.status = 'pending';
        state.loading = true;
      }).addCase(signUp.fulfilled, (state, action) => {
      state.status = 'success';
      state.loading = false;
      state.signup.success = true;
    }).addCase(signUp.rejected, (state, action) => {
      state.status = 'failed';
      state.loading = false;
      state.error = action.error as IAuthError;
    });
    builder
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword.loading = false;
        state.resetPassword.isComplete = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPassword.loading = false;
        state.resetPassword.error = action.error.message as unknown as IAuthError;
      });
    builder
      .addCase(sendConfirmation.pending, (state) => {
        state.resetPassword.loading = true;
      })
      .addCase(sendConfirmation.fulfilled, (state, action) => {
        state.resetPassword.loading = false;
        state.resetPassword.otpSent = true;
      })
      .addCase(sendConfirmation.rejected, (state, action) => {
        state.resetPassword.loading = false;
        state.error = action.payload as IAuthError;
      });
    builder
      .addCase(confirmEmail.pending, (state) => {
        state.loading= true;
        state.signup.emailConfirmationSuccess=false;
      })
      .addCase(confirmEmail.fulfilled, (state, action) => {
        state.loading= false;
        state.signup.emailConfirmationSuccess=true;
      })
      .addCase(confirmEmail.rejected, (state, action) => {
        state.loading= false;
        state.error = action.error as IAuthError;
        state.signup.emailConfirmationSuccess=false;
      });
    builder
      .addCase(signOut.pending, (state) => {
        state.loading= true;
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.loading= false;
        state.user = null;
      })
      .addCase(signOut.rejected, (state, action) => {
        state.loading= false;
        state.error = action.error as IAuthError;
      });
  }
});

export const selectAuth = (state: RootState) => state.auth;
export const { passwordResetInProgress, sendingOTPInProgress, clearUpState, currentUser } = authSlice.actions

export default authSlice.reducer;
