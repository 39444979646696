import { useMediaQuery } from "hooks/useMediaQuery";
import { useMemo, ReactNode, useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { Outlet, useOutlet } from "react-router-dom";
import Styles from "./index.module.css";

function Layout(props: { children: ReactNode }) {
  const outlet = useOutlet();

  const isMobileView = useMediaQuery("(max-width: 1000px)");
  const [outletModal, setOutletModal] = useState(false);
  const [rightCompVisible, setRightCompVisible] = useState(false);

  const middleCompClass = useMemo(() => {
    if (rightCompVisible) {
      return Styles.mainLeftStretched;
    }

    if(outletModal){
      return  Styles.hide
    }

    return Styles.mainFullStretched;
  }, [rightCompVisible]);

  useEffect(() => {
    if (!outlet) {
      setOutletModal(false);
      setRightCompVisible(false);
    } else if (isMobileView) {
      setOutletModal(true);
      setRightCompVisible(false);
    } else {
      setOutletModal(false);
      setRightCompVisible(true);
    }
  }, [isMobileView, outlet]);

  if (outletModal){
    return (
      <Div100vh
          style={{ zIndex: 1001 }}
          className={Styles.modalContainer}
        >
          <Outlet />
        </Div100vh>
    )
  }

  return (
    <div className={Styles.container}>
      <div className={middleCompClass}>{props.children}</div>
      {rightCompVisible ? (
        <Div100vh className={Styles.rightpanel}>{outlet || <></>}</Div100vh>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Layout;
